import {
  Badge,
  Fade,
  IconButton,
  List,
  ListSubheader,
  Menu,
  Tooltip, Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Bell as BellIcon } from '../icons/bell';
import { useTranslation } from 'next-i18next';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import ProjectCostNotificationActive from './notifications/active/project-cost-notification';
import ProjectManagerNotification from './notifications/active/project-manager-notification';
import ProjectAccountantNotification from './notifications/active/project-accountant-notification';
import ProjectForemanNotification from './notifications/active/project-foreman-notification';
import ProjectCostAddedNotification from './notifications/active/project-cost-added-notification';
import ProjectCostAcceptedNotification
  from './notifications/active/project-cost-accepted-notification';
import ProjectCostDeclinedNotification
  from './notifications/active/project-cost-declined-notification';
import ProjectCostAddedAdminNotification
  from './notifications/active/project-cost-added-admin-notification';
import ProjectManagerAgreeWorkerCertificateNotification
  from './notifications/active/project-manager-agree-worker-certificate';
import ProjectAccountantSendAgreementsForWorkerNotification
  from './notifications/active/project-accountant-send-agreements-for-worker';
import AdminAgreeNewWorkerNotification from './notifications/active/admin-agree-new-worker';
import ProjectForemanAddNewWorkerPaymentsNotification
  from './notifications/active/project-foreman-to-add-new-worker-payment-notification';
import ProjectForemanNewProjectSupplierNotification
  from './notifications/active/project-foreman-notification-new-supplier';
import ProjectForemanNewProjectSupplierOfferNotification
  from './notifications/active/project-foreman-notification-new-supplier-offer';
import ProjectForemanSupplierDeclinedNotification
  from './notifications/active/project-foreman-notification-supplier-declined';
import ProjectForemanSupplierOfferDeclinedNotification
  from './notifications/active/project-foreman-notification-supplier-offer-declined';
import ProjectForemanSupplierApprovedNotification
  from './notifications/active/project-foreman-notification-supplier-approved';
import ProjectForemanSupplierOfferApprovedNotification
  from './notifications/active/project-foreman-notification-supplier-offer-approved';
import ProjectForemanSupplierDeclinedAdminNotification
  from './notifications/active/project-foreman-notification-supplier-declined-admin';
import ProjectManagerAgreeSubcontractorCertificateNotification
  from './notifications/active/project-manager-agree-subcontractor-certificate';
import ProjectForemanSubcontractorDeclinedNotification
  from './notifications/active/project-foreman-notification-subcontractor-declined';
import ProjectForemanSubcontractorApprovedNotification
  from './notifications/active/project-foreman-notification-subcontractor-approved';
import ProjectForemanAddNewSubcontractorPaymentsNotification
  from './notifications/active/project-foreman-to-add-new-subcontractor-payment-notification';
import ProjectAccountantNewFutureWorkerExpenseNotification
  from './notifications/active/project-accountant-new-future-worker-expense-notification';
import ProjectAccountantNewTodayWorkerExpenseNotification
  from './notifications/active/project-accountant-new-today-worker-expense-notification';
import ProjectManagerNewWorkerExpenseDeclinedNotification
  from './notifications/active/project-manager-new-worker-expense-declined-notification';
import ProjectManagerNewWorkerExpenseApprovedNotification
  from './notifications/active/project-manager-new-worker-expense-approved-notification';
import AdminAgreeNewWorkerExpenseNotification
  from './notifications/active/admin-agree-new-worker-expense';
import ProjectAccountantNewFutureSupplierExpenseNotification
  from './notifications/active/project-accountant-new-future-supplier-expense-notification';
import ProjectAccountantNewTodaySupplierExpenseNotification
  from './notifications/active/project-accountant-new-today-supplier-expense-notification';
import AdminAgreeNewSupplierExpenseNotification
  from './notifications/active/admin-agree-new-supplier-expense';
import ProjectManagerNewSupplierExpenseApprovedNotification
  from './notifications/active/project-manager-new-supplier-expense-approved-notification';
import ProjectManagerNewSupplierExpenseDeclinedNotification
  from './notifications/active/project-manager-new-supplier-expense-declined-notification';
import ProjectAccountantNewFutureSubcontractorExpenseNotification
  from './notifications/active/project-accountant-new-future-subcontractor-expense-notification';
import ProjectAccountantNewTodaySubcontractorExpenseNotification
  from './notifications/active/project-accountant-new-today-subcontractor-expense-notification';
import AdminAgreeNewSubcontractorExpenseNotification
  from './notifications/active/admin-agree-new-subcontractor-expense';
import ProjectManagerNewSubcontractorExpenseApprovedNotification
  from './notifications/active/project-manager-new-subcontractor-expense-approved-notification';
import ProjectManagerNewSubcontractorExpenseDeclinedNotification
  from './notifications/active/project-manager-new-subcontractor-expense-declined-notification';
import ProjectAccountantNewFutureOtherExpenseNotification
  from './notifications/active/project-accountant-new-future-other-expense-notification';
import ProjectAccountantNewTodayOtherExpenseNotification
  from './notifications/active/project-accountant-new-today-other-expense-notification';
import AdminAgreeNewOtherExpenseNotification
  from './notifications/active/admin-agree-new-other-expense';
import ProjectManagerNewOtherExpenseApprovedNotification
  from './notifications/active/project-manager-new-other-expense-approved-notification';
import ProjectManagerNewOtherExpenseDeclinedNotification
  from './notifications/active/project-manager-new-other-expense-declined-notification';
import ProjectForemanFillWorkerPaymentAgreementNotification
  from './notifications/active/project-foreman-notification-fill-worker-payment-agreement';
import ProjectAccountantFilledWorkerPaymentAgreementUploadedNotification
  from './notifications/active/project-accountant-filled-worker-payment-agreements-uploaded-notification';
import { useRouter } from 'next/router';
import ProjectOnlyCostDeclinedNotification
  from './notifications/active/project-only-cost-declined-notification';
import ProjectOnlyDescriptiveDeclinedNotification
  from './notifications/active/project-only-descriptive-declined-notification';
import AdminProjectManagerNewCostUploadedNotification
  from './notifications/active/admin-project-manager-new-cost';
import AdminProjectManagerNewDescriptiveUploadedNotification
  from './notifications/active/admin-project-manager-new-descriptive';
import AdminProjectManagerNewInstallmentUploadedNotification
  from './notifications/active/admin-project-manager-new-installment';
import AdminOnlyCostAgreedByProjectManagerNotification
  from './notifications/active/admin-only-cost-agreed-by-project-manager';
import AdminOnlyCostDeclinedByProjectManagerNotification
  from './notifications/active/admin-only-cost-agreed-by-project-manager';
import AdminOnlyDescriptiveAgreedByProjectManagerNotification
  from './notifications/active/admin-only-descriptive-agreed-by-project-manager';
import AdminOnlyDescriptiveDeclinedByProjectManagerNotification
  from './notifications/active/admin-only-descriptive-declined-by-project-manager';
import AdminOnlyInstallmentAgreedByProjectManagerNotification
  from './notifications/active/admin-only-installment-agreed-by-project-manager';
import AdminOnlyInstallmentDeclinedByProjectManagerNotification
  from './notifications/active/admin-only-installment-declined-by-project-manager';
import AdminAllCostAgreedByProjectManagerNotification
  from './notifications/active/admin-all-cost-agreed-by-project-manager';
import AdminAllCostDeclinedByProjectManagerNotification
  from './notifications/active/admin-all-cost-declined-by-project-manager';
import AdminAcceptNewAddedProjectNotification
  from './notifications/active/admin-accept-new-added-project';
import ProjectManagerAfterCostNotification
  from './notifications/active/project-manager-after-cost-notification';
import ProjectCostManagerSubmitCostUntilNotification
  from './notifications/active/project-cost-manager-submit-cost-until-notification';
import ProjectCostManagerSubmitCostUrgentNotification
  from './notifications/active/project-cost-manager-submit-cost-urgent-notification';
import ProjectAddedByProjectImportRejectedNotification
  from './notifications/active/project-import-rejected';
import ProjectAddedByProjectImportApprovedNotification
  from './notifications/active/project-import-approved';
import ProjectForemanFillWorkerDetailsNotification
  from './notifications/active/project-foreman-fill-worker-details-notification';
import ProjectAccountantFillCollaboratorAgreementNotification
  from './notifications/active/project-accountant-fill-collaborator-agreement';
import ProjectForemanAddDailyUpdateNotification
  from './notifications/active/project-foreman-add-daily-update';
import ProjectOnlyTimeScheduleDeclinedNotification
  from './notifications/active/project-only-time-schedule-declined-notification';
import ProjectOnlyCustomerSumDeclinedNotification
  from './notifications/active/project-only-customer-sum-declined-notification';
import ProjectOnlyCustomerPerCategoryDeclinedNotification
  from './notifications/active/project-only-customer-per-category-declined-notification';
import ProjectOnlyCustomerAnalysisDeclinedNotification
  from './notifications/active/project-only-customer-analysis-declined-notification';
import ProjectOnlyClarificationDeclinedNotification
  from './notifications/active/project-only-clarification-declined-notification';
import AdminAcceptNewCashOfferChangeNotification
  from './notifications/active/admin-accept-new-cash-change';
import AdminProjectManagerCostReplacedNotification from './notifications/active/cost-replaced';

export default function NotificationsDropdown(){
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const router = useRouter()
  const [cookies, setCookie, removeCookie] = useCookies(['token'])
  const [firstLoad, setFirstLoad] = useState(true)

  const [allNotifications, setAllNotifications] = useState([])
  const [allDeactiveNotifications, setAllDeactiveNotifications] = useState([])

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function getUserNotifications(){
    let config = {
      method: 'get',
      url: '/api/notifications/getActiveByUser',
      headers: {
        'Authorization': 'Bearer ' + cookies.token
      }
    };

    axios(config)
      .then(function (response) {
        const allResponse = response.data
        setAllNotifications(allResponse['all_notifications'])
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //First Load useEffect
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (firstLoad) {
        if (typeof cookies.token === "undefined") {
          router.push("/login").then()
          return
        }
        getUserNotifications()
        setFirstLoad(false)
      }
    }
  })

  //Refresh Every 1 Second useEffect
  useEffect(() => {
    const interval = setInterval(() => {
      refreshNotifications()
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  //Refresh Every Page Change useEffect
  useEffect(() => {
    const handleRouteChange = (url) => {
      refreshNotifications()
    }

    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  function refreshNotifications(){
    getUserNotifications()
  }

  function generateDate(dateValue){
    const parsedDate = new Date(dateValue)
    let returnString = ""
    if (parsedDate.getDate() < 10){
      returnString += "0" + parsedDate.getDate().toString()
    } else {
      returnString += parsedDate.getDate().toString()
    }
    if ((parsedDate.getMonth() + 1) < 10){
      returnString += "-0" + (parsedDate.getMonth() + 1).toString()
    } else {
      returnString += "-" + (parsedDate.getMonth() + 1).toString()
    }
    returnString += "-" + parsedDate.getFullYear()
    return returnString
  }

  return (
    <div>
      <Tooltip title={t('notifications')}
               onClick={handleClick}>
        <IconButton sx={{ ml: 1 }}>
          <Badge
            badgeContent={allNotifications.length}
            color="primary"
            showZero
          >
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <div style={{maxHeight: 300, overflow: 'auto'}}>
          <List>
            <ListSubheader>{t('active_notifications')}</ListSubheader>
            {allNotifications.length === 0 &&
              <>
                <Typography
                  color="textPrimary"
                  variant="span"
                  style={{paddingLeft: 15, paddingRight: 10, fontSize: 12}}
                >
                  {t('no_active_notifications')}
                </Typography>
              </>
            }
            {allNotifications.length !== 0 &&
              <>
                {allNotifications.map((notificationObj, i) => {
                  if (notificationObj['notification_template']['id'] === 1) {
                    //Project for Costing Notification
                    return (
                      <>
                        <ProjectCostNotificationActive
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 2) {
                    //Project for Costing Notification
                    return (
                      <>
                        <ProjectManagerNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 3) {
                    //Project for Costing Notification
                    return (
                      <>
                        <ProjectAccountantNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 4) {
                    //Project for Costing Notification
                    return (
                      <>
                        <ProjectForemanNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 5) {
                    //Project for Costing Notification
                    return (
                      <>
                        <ProjectCostAddedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 6) {
                    //Project for Costing Notification
                    return (
                      <>
                        <ProjectCostAcceptedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          projectID={notificationObj['reference_large_project']['id']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 7) {
                    //Project for Costing Notification
                    return (
                      <>
                        <ProjectCostDeclinedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 8) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectCostAddedAdminNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 9) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectManagerAgreeWorkerCertificateNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 10) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectAccountantSendAgreementsForWorkerNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 11) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminAgreeNewWorkerNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 12) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectForemanAddNewWorkerPaymentsNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 13) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectForemanNewProjectSupplierNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 14) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectForemanSupplierDeclinedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 15) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectForemanSupplierApprovedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 16) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectForemanSupplierDeclinedAdminNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 17) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectManagerAgreeSubcontractorCertificateNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 18) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectForemanSubcontractorDeclinedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 19) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectForemanSubcontractorApprovedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 20) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectForemanAddNewSubcontractorPaymentsNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 21) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectAccountantNewFutureWorkerExpenseNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['large_project']['id']}
                          projectName={notificationObj['large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          payOffDate={generateDate(notificationObj['reference_worker_expense']['pay_off_date'])}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 22) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectAccountantNewTodayWorkerExpenseNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['large_project']['id']}
                          projectName={notificationObj['large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          payOffDate={generateDate(notificationObj['reference_worker_expense']['pay_off_date'])}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 23) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectManagerNewWorkerExpenseDeclinedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_large_project']['id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 24) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectManagerNewWorkerExpenseApprovedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_large_project']['id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 25) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminAgreeNewWorkerExpenseNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_large_project']['id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 26) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectAccountantNewFutureSupplierExpenseNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['large_project']['id']}
                          projectName={notificationObj['large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          payOffDate={generateDate(notificationObj['reference_supplier_expense']['pay_off_date'])}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 27) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectAccountantNewTodaySupplierExpenseNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['large_project']['id']}
                          projectName={notificationObj['large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          payOffDate={generateDate(notificationObj['reference_supplier_expense']['pay_off_date'])}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 28) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminAgreeNewSupplierExpenseNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_large_project']['id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 29) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectManagerNewSupplierExpenseApprovedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_large_project']['id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 30) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectManagerNewSupplierExpenseDeclinedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_large_project']['id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 31) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectAccountantNewFutureSubcontractorExpenseNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['large_project']['id']}
                          projectName={notificationObj['large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          payOffDate={generateDate(notificationObj['reference_subcontractor_expense']['pay_off_date'])}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 32) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectAccountantNewTodaySubcontractorExpenseNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['large_project']['id']}
                          projectName={notificationObj['large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          payOffDate={generateDate(notificationObj['reference_subcontractor_expense']['pay_off_date'])}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 33) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminAgreeNewSubcontractorExpenseNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_large_project']['id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 34) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectManagerNewSubcontractorExpenseApprovedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_large_project']['id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 35) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectManagerNewSubcontractorExpenseDeclinedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_large_project']['id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 36) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectAccountantNewFutureOtherExpenseNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['large_project']['id']}
                          projectName={notificationObj['large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          payOffDate={generateDate(notificationObj['reference_other_expense']['pay_off_date'])}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 37) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectAccountantNewTodayOtherExpenseNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['large_project']['id']}
                          projectName={notificationObj['large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          payOffDate={generateDate(notificationObj['reference_other_expense']['pay_off_date'])}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 38) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminAgreeNewOtherExpenseNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_large_project']['id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 39) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectManagerNewOtherExpenseApprovedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_large_project']['id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 40) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectManagerNewOtherExpenseDeclinedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_large_project']['id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 41) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectForemanFillWorkerPaymentAgreementNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 42) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectAccountantFilledWorkerPaymentAgreementUploadedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 44) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectOnlyCostDeclinedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 46) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectOnlyDescriptiveDeclinedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 59) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerNewCostUploadedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 60) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerNewDescriptiveUploadedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 61) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerNewInstallmentUploadedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 62) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminOnlyCostAgreedByProjectManagerNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 63) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminOnlyCostDeclinedByProjectManagerNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 64) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminOnlyDescriptiveAgreedByProjectManagerNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 65) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminOnlyDescriptiveDeclinedByProjectManagerNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 66) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminOnlyInstallmentAgreedByProjectManagerNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 67) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminOnlyInstallmentDeclinedByProjectManagerNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 68) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminAllCostAgreedByProjectManagerNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 69) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminAllCostDeclinedByProjectManagerNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 70) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminAcceptNewAddedProjectNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 71) {
                    //Project for Costing Notification
                    return (
                      <>
                        <ProjectManagerAfterCostNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 72) {
                    //Project for Costing Notification
                    return (
                      <>
                        <ProjectCostManagerSubmitCostUntilNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          untilDate={notificationObj['reference_large_project']['cost_phase_until']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 73) {
                    //Project for Costing Notification
                    return (
                      <>
                        <ProjectCostManagerSubmitCostUrgentNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          untilDate={notificationObj['reference_large_project']['cost_phase_until']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 74) {
                    //Project for Costing Notification
                    return (
                      <>
                        <ProjectAddedByProjectImportRejectedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                          comments={notificationObj['reference_large_project']['final_agree_comments']}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 75) {
                    //Project for Costing Notification
                    return (
                      <>
                        <ProjectAddedByProjectImportApprovedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                          comments={notificationObj['reference_large_project']['final_agree_comments']}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 76) {
                    //Project for Costing Notification
                    return (
                      <>
                        <ProjectForemanFillWorkerDetailsNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_large_project']['id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                          workerName={notificationObj['reference_collaborator']['first_name'] + " " + notificationObj['reference_collaborator']['last_name']}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 77) {
                    //Project for Costing Notification
                    return (
                      <>
                        <ProjectAccountantFillCollaboratorAgreementNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 78) {
                    //Project for Costing Notification
                    return (
                      <>
                        <ProjectForemanAddDailyUpdateNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          createdAtDate={notificationObj['created_at']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 79) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerNewCostUploadedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 80) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerNewCostUploadedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 81) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerNewCostUploadedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 82) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerNewCostUploadedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 83) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerNewCostUploadedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 84) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectOnlyTimeScheduleDeclinedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 85) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectOnlyCustomerSumDeclinedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 86) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectOnlyCustomerPerCategoryDeclinedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 87) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectOnlyCustomerAnalysisDeclinedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 88) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectOnlyClarificationDeclinedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 89) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminAcceptNewCashOfferChangeNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 90) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminAcceptNewCashOfferChangeNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 91) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminAcceptNewCashOfferChangeNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 92) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminAcceptNewCashOfferChangeNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 93) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminAcceptNewCashOfferChangeNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 94) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminAcceptNewCashOfferChangeNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 95) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminAcceptNewCashOfferChangeNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 96) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminAcceptNewCashOfferChangeNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 97) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminAcceptNewCashOfferChangeNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 98) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminAcceptNewCashOfferChangeNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 99) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerCostReplacedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 100) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerCostReplacedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 101) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerCostReplacedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 102) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerCostReplacedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 103) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerCostReplacedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 104) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerCostReplacedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 105) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerCostReplacedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 106) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerCostReplacedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 107) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerCostReplacedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 108) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerCostReplacedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 109) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerCostReplacedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 110) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerCostReplacedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 111) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerCostReplacedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 112) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerCostReplacedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 113) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerCostReplacedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 114) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerCostReplacedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 115) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <AdminProjectManagerCostReplacedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 116) {
                    //Project Has New Cost Notification
                    return (
                      <>
                        <ProjectCostAddedAdminNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 118) {
                    return (
                      <>
                        <ProjectForemanSupplierOfferApprovedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 119) {
                    return (
                      <>
                        <ProjectForemanNewProjectSupplierOfferNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                  if (notificationObj['notification_template']['id'] === 120) {
                    return (
                      <>
                        <ProjectForemanSupplierOfferDeclinedNotification
                          translatableMessage={notificationObj['notification_template']['name']}
                          projectID={notificationObj['reference_id']}
                          projectName={notificationObj['reference_large_project']['name']}
                          addedByName={notificationObj['added_by_user']['first_name'] + " " + notificationObj['added_by_user']['last_name']}
                          notificationID={notificationObj.id}
                          token={cookies.token}
                          handleModalClose={handleClose}
                          refreshList={refreshNotifications}
                          fromDashboard={false}
                        />
                      </>
                    )
                  }
                })}
              </>
            }
          </List>
        </div>
      </Menu>
    </div>
  );
}
