import { useEffect, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, List, useMediaQuery } from '@mui/material';
import { Users as UsersIcon } from '../icons/users';
import { Logo } from './logo';
import { NavItem } from './nav-item';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'next-i18next';
import { DashboardIcon } from '../icons/DashboardIcon';
import { RolesIcon } from '../icons/RolesIcon';
import { isJwtExpired } from 'jwt-check-expiration';
import AddIcon from '@mui/icons-material/Add';
import WorkIcon from '@mui/icons-material/Work';
import jwtDecode from 'jwt-decode';
import axios from "axios";
import CategoryIcon from '@mui/icons-material/Category';
import PeopleIcon from '@mui/icons-material/People';
import GroupsIcon from '@mui/icons-material/Groups';
import EngineeringIcon from '@mui/icons-material/Engineering';
import StoreIcon from '@mui/icons-material/Store';
import GavelIcon from '@mui/icons-material/Gavel';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import SearchIcon from '@mui/icons-material/Search';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export function DashboardSidebar(props) {
  const { open, onClose } = props;
  const { t } = useTranslation();
  const router = useRouter();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false
  });
  const [cookies, setCookie, removeCookie] = useCookies(['token'])
  const [width, setWidth] = useState(window.innerWidth);
  const [largeProjectRights, setLargeProjectRights] = useState([])
  const [usersRights, setUsersRights] = useState([])
  const [rolesRights, setRolesRights] = useState([])
  const [largeProjectCategoryRights, setLargeProjectCategoryRight] = useState([])
  const [costCategoriesRights, setCostCategoriesRights] = useState([])
  const [customersRights, setCustomersRights] = useState([])
  const [collaboratorRights, setCollaboratorRights] = useState([])
  const [settingsRights, setSettingsRights] = useState([])
  const [taxRatesRights, setTaxRatesRights] = useState([])
  const [citiesRights, setCitiesRights] = useState([])
  //Index:
  //0 = Create
  //1 = Read
  //2 = Update
  //3 = Delete
  //4 = Show On Menu
  const [largeProjectWithoutCost, setLargeProjectWithoutCost] = useState(false)

  let loginCookie = cookies.token
  if (typeof loginCookie === "undefined" || isJwtExpired(cookies.token)){
    const currentPath = router.asPath
    router.push("/login?route=" + currentPath).then()
  }

  function getRoleDetails(){
    const userDetails = jwtDecode(cookies.token)
    const roleID = userDetails['user_details']['role_id']

    let config = {
      method: 'get',
      url: '/api/roles/' + roleID,
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + cookies.token
      }
    };

    axios(config)
      .then(function (response) {
        const allResponse = response.data
        serializeRoleDetails(allResponse)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function serializeRoleDetails(data){
    let largeProjectValues = []
    let usersValues = []
    let rolesValues = []
    let categoriesValues = []
    let customersValue = []
    let collaboratorValue = []
    let settings = []
    let taxRates = []
    let cities = []
    let costCategories = []
    for (let i = 0; i<data['specific_roles'][0]['role_rights'].length; i++){
      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "LargeProjects"){
        const entityValue = data['specific_roles'][0]['role_rights'][i]['value']
        const binaryValue = parseInt(entityValue, 10).toString(2).padStart(4, "0")
        if (binaryValue.charAt(0) === '1'){
          largeProjectValues.push(true)
        } else {
          largeProjectValues.push(false)
        }

        if (binaryValue.charAt(1) === '1'){
          largeProjectValues.push(true)
        } else {
          largeProjectValues.push(false)
        }

        if (binaryValue.charAt(2) === '1'){
          largeProjectValues.push(true)
        } else {
          largeProjectValues.push(false)
        }

        if (binaryValue.charAt(3) === '1'){
          largeProjectValues.push(true)
        } else {
          largeProjectValues.push(false)
        }

        if (data['specific_roles'][0]['role_rights'][i]['menu_display']){
          largeProjectValues.push(true)
        } else {
          largeProjectValues.push(false)
        }
      }

      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "Users"){
        const entityValue = data['specific_roles'][0]['role_rights'][i]['value']
        const binaryValue = parseInt(entityValue, 10).toString(2).padStart(4, "0")
        if (binaryValue.charAt(0) === '1'){
          usersValues.push(true)
        } else {
          usersValues.push(false)
        }

        if (binaryValue.charAt(1) === '1'){
          usersValues.push(true)
        } else {
          usersValues.push(false)
        }

        if (binaryValue.charAt(2) === '1'){
          usersValues.push(true)
        } else {
          usersValues.push(false)
        }

        if (binaryValue.charAt(3) === '1'){
          usersValues.push(true)
        } else {
          usersValues.push(false)
        }

        if (data['specific_roles'][0]['role_rights'][i]['menu_display']){
          usersValues.push(true)
        } else {
          usersValues.push(false)
        }
      }

      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "Roles"){
        const entityValue = data['specific_roles'][0]['role_rights'][i]['value']
        const binaryValue = parseInt(entityValue, 10).toString(2).padStart(4, "0")
        if (binaryValue.charAt(0) === '1'){
          rolesValues.push(true)
        } else {
          rolesValues.push(false)
        }

        if (binaryValue.charAt(1) === '1'){
          rolesValues.push(true)
        } else {
          rolesValues.push(false)
        }

        if (binaryValue.charAt(2) === '1'){
          rolesValues.push(true)
        } else {
          rolesValues.push(false)
        }

        if (binaryValue.charAt(3) === '1'){
          rolesValues.push(true)
        } else {
          rolesValues.push(false)
        }

        if (data['specific_roles'][0]['role_rights'][i]['menu_display']){
          rolesValues.push(true)
        } else {
          rolesValues.push(false)
        }
      }

      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "LargeProjectsCategories"){
        const entityValue = data['specific_roles'][0]['role_rights'][i]['value']
        const binaryValue = parseInt(entityValue, 10).toString(2).padStart(4, "0")
        if (binaryValue.charAt(0) === '1'){
          categoriesValues.push(true)
        } else {
          categoriesValues.push(false)
        }

        if (binaryValue.charAt(1) === '1'){
          categoriesValues.push(true)
        } else {
          categoriesValues.push(false)
        }

        if (binaryValue.charAt(2) === '1'){
          categoriesValues.push(true)
        } else {
          categoriesValues.push(false)
        }

        if (binaryValue.charAt(3) === '1'){
          categoriesValues.push(true)
        } else {
          categoriesValues.push(false)
        }

        if (data['specific_roles'][0]['role_rights'][i]['menu_display']){
          categoriesValues.push(true)
        } else {
          categoriesValues.push(false)
        }
      }

      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "Customers"){
        const entityValue = data['specific_roles'][0]['role_rights'][i]['value']
        const binaryValue = parseInt(entityValue, 10).toString(2).padStart(4, "0")
        if (binaryValue.charAt(0) === '1'){
          customersValue.push(true)
        } else {
          customersValue.push(false)
        }

        if (binaryValue.charAt(1) === '1'){
          customersValue.push(true)
        } else {
          customersValue.push(false)
        }

        if (binaryValue.charAt(2) === '1'){
          customersValue.push(true)
        } else {
          customersValue.push(false)
        }

        if (binaryValue.charAt(3) === '1'){
          customersValue.push(true)
        } else {
          customersValue.push(false)
        }

        if (data['specific_roles'][0]['role_rights'][i]['menu_display']){
          customersValue.push(true)
        } else {
          customersValue.push(false)
        }
      }

      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "Collaborators"){
        const entityValue = data['specific_roles'][0]['role_rights'][i]['value']
        const binaryValue = parseInt(entityValue, 10).toString(2).padStart(4, "0")
        if (binaryValue.charAt(0) === '1'){
          collaboratorValue.push(true)
        } else {
          collaboratorValue.push(false)
        }

        if (binaryValue.charAt(1) === '1'){
          collaboratorValue.push(true)
        } else {
          collaboratorValue.push(false)
        }

        if (binaryValue.charAt(2) === '1'){
          collaboratorValue.push(true)
        } else {
          collaboratorValue.push(false)
        }

        if (binaryValue.charAt(3) === '1'){
          collaboratorValue.push(true)
        } else {
          collaboratorValue.push(false)
        }

        if (data['specific_roles'][0]['role_rights'][i]['menu_display']){
          collaboratorValue.push(true)
        } else {
          collaboratorValue.push(false)
        }
      }

      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "CanManageSettings"){
        const entityValue = data['specific_roles'][0]['role_rights'][i]['value']
        const binaryValue = parseInt(entityValue, 10).toString(2).padStart(4, "0")
        if (binaryValue.charAt(0) === '1'){
          settings.push(true)
        } else {
          settings.push(false)
        }

        if (binaryValue.charAt(1) === '1'){
          settings.push(true)
        } else {
          settings.push(false)
        }

        if (binaryValue.charAt(2) === '1'){
          settings.push(true)
        } else {
          settings.push(false)
        }

        if (binaryValue.charAt(3) === '1'){
          settings.push(true)
        } else {
          settings.push(false)
        }

        if (data['specific_roles'][0]['role_rights'][i]['menu_display']){
          settings.push(true)
        } else {
          settings.push(false)
        }
      }

      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "TaxRates"){
        const entityValue = data['specific_roles'][0]['role_rights'][i]['value']
        const binaryValue = parseInt(entityValue, 10).toString(2).padStart(4, "0")
        if (binaryValue.charAt(0) === '1'){
          taxRates.push(true)
        } else {
          taxRates.push(false)
        }

        if (binaryValue.charAt(1) === '1'){
          taxRates.push(true)
        } else {
          taxRates.push(false)
        }

        if (binaryValue.charAt(2) === '1'){
          taxRates.push(true)
        } else {
          taxRates.push(false)
        }

        if (binaryValue.charAt(3) === '1'){
          taxRates.push(true)
        } else {
          taxRates.push(false)
        }

        if (data['specific_roles'][0]['role_rights'][i]['menu_display']){
          taxRates.push(true)
        } else {
          taxRates.push(false)
        }
      }

      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "Cities"){
        const entityValue = data['specific_roles'][0]['role_rights'][i]['value']
        const binaryValue = parseInt(entityValue, 10).toString(2).padStart(4, "0")
        if (binaryValue.charAt(0) === '1'){
          cities.push(true)
        } else {
          cities.push(false)
        }

        if (binaryValue.charAt(1) === '1'){
          cities.push(true)
        } else {
          cities.push(false)
        }

        if (binaryValue.charAt(2) === '1'){
          cities.push(true)
        } else {
          cities.push(false)
        }

        if (binaryValue.charAt(3) === '1'){
          cities.push(true)
        } else {
          cities.push(false)
        }

        if (data['specific_roles'][0]['role_rights'][i]['menu_display']){
          cities.push(true)
        } else {
          cities.push(false)
        }
      }

      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "LargeProjectsCanAddWithoutCost") {
        const entityValue = data['specific_roles'][0]['role_rights'][i]['value']
        if (entityValue === 15){
          setLargeProjectWithoutCost(true)
        }
      }

      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "CostCategories"){
        const entityValue = data['specific_roles'][0]['role_rights'][i]['value']
        const binaryValue = parseInt(entityValue, 10).toString(2).padStart(4, "0")
        if (binaryValue.charAt(0) === '1'){
          costCategories.push(true)
        } else {
          costCategories.push(false)
        }

        if (binaryValue.charAt(1) === '1'){
          costCategories.push(true)
        } else {
          costCategories.push(false)
        }

        if (binaryValue.charAt(2) === '1'){
          costCategories.push(true)
        } else {
          costCategories.push(false)
        }

        if (binaryValue.charAt(3) === '1'){
          costCategories.push(true)
        } else {
          costCategories.push(false)
        }

        if (data['specific_roles'][0]['role_rights'][i]['menu_display']){
          costCategories.push(true)
        } else {
          costCategories.push(false)
        }
      }
    }
    setLargeProjectRights(largeProjectValues)
    setUsersRights(usersValues)
    setRolesRights(rolesValues)
    setLargeProjectCategoryRight(categoriesValues)
    setCustomersRights(customersValue)
    setCollaboratorRights(collaboratorValue)
    setSettingsRights(settings)
    setTaxRatesRights(taxRates)
    setCitiesRights(cities)
    setCostCategoriesRights(costCategories)
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(
    () => {
      getRoleDetails()
      if (!router.isReady) {
        return;
      }


      if (width <= 768) {
        if (open) {
          onClose?.();
        }
      }
    },
    [router.asPath]
  );

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const items = [
    {
      href: '/',
      icon: (<DashboardIcon fontSize="small" />),
      title: 'dashboard',
      child: []
    },
    (usersRights[4]) ?
    {
      href: '/users|*&*|/user/add|*&*|/user/[id]|*&*|/user/[id]/edit',
      icon: (<UsersIcon fontSize="small" />),
      title: 'users',
      child: [
        (usersRights[1]) ?
        {
          href: '/users',
          icon: (<UsersIcon fontSize="small" />),
          title: "show_all"
        } : {},
        (usersRights[0]) ?
        {
          href: '/user/add',
          icon: (<AddIcon fontSize="small" />),
          title: "user_add"
        } : {}
      ]
    } : {},
    (rolesRights[4]) ?
    {
      href: '/roles|*&*|/role/add|*&*|/role/[id]|*&*|/role/[id]/edit',
      icon: (<RolesIcon fontSize="small" />),
      title: 'roles',
      child: [
        (rolesRights[1]) ?
        {
          href: '/roles',
          icon: (<RolesIcon fontSize="small" />),
          title: "show_all"
        } : {},
        (rolesRights[0]) ?
        {
          href: '/role/add',
          icon: (<AddIcon fontSize="small" />),
          title: "add_role"
        } : {}
      ]
    } : {},
    (largeProjectRights[4]) ?
    {
      href: '/projects|*&*|/projects/categories|*&*|/projects/category/[id]|*&*|/projects/category/[id]/edit|*&*|/projects/category/add|*&*|/projects/add-income|*&*|/projects/search|*&*|/project/[id]|*&*|/project/[id]/recover|*&*|/project/[id]/income|*&*|/project/[id]/edit|*&*|/project/[id]/add-only-time-schedule|*&*|/project/[id]/add-only-cost|*&*|/project/[id]/add-cost|*&*|/project/[id]/add-contract-offer|*&*|/project/[id]/add-cash-offer|*&*|/project/[id]/worker-report|*&*|/project/[id]/expenses|*&*|/project/[id]/expenses/add-other|*&*|/project/[id]/expenses/add-subcontractor|*&*|/project/[id]/expenses/add-supplier|*&*|/project/[id]/expenses/add-worker|*&*|/project/[id]/expenses/add-worker-food|*&*|/project/[id]/expenses/add-worker-stay|*&*|/project/[id]/daily-update|*&*|/project/[id]/daily-update/all|*&*|/project/[id]/certificates|*&*|/project/[id]/certificates/add-subcontractor|*&*|/project/[id]/certificates/add-supplier|*&*|/project/[id]/certificates/add-worker|*&*|/project/add|*&*|/project/add/without-cost',
      icon: (<WorkIcon fontSize="small" />),
      title: 'projects',
      child: [
        (largeProjectRights[1]) ?
          {
            href: '/projects/search',
            icon: (<SearchIcon fontSize="small" />),
            title: "large_projects_search"
          } : {},
        (largeProjectRights[1]) ?
        {
          href: '/projects',
          icon: (<WorkIcon fontSize="small" />),
          title: "show_all"
        } : {},
        (largeProjectRights[0]) ?
        {
          href: '/project/add',
          icon: (<AddIcon fontSize="small" />),
          title: "add_project"
        } : {},
        (largeProjectWithoutCost) ?
          {
            href: '/project/add/without-cost',
            icon: (<AddIcon fontSize="small" />),
            title: "add_project_without_cost"
          } : {},
        (largeProjectCategoryRights[4]) ?
        {
          href: '/projects/categories',
          icon: (<CategoryIcon fontSize="small"/>),
          title: "large_project_categories"
        } : {}
      ]
    } : {},
    (costCategoriesRights[4]) ? {
      href: '/cost-categories|*&*|/cost-category/add|*&*|/cost-category/[id]|*&*|/cost-category/[id]/edit',
      icon: (<AccountTreeIcon fontSize="small" />),
      title: 'cost_categories',
      child: [
        (costCategoriesRights[1]) ? {
          href: '/cost-categories',
          icon: (<FormatListBulletedIcon fontSize="small" />),
          title: "show_all"
        } : {},
        (costCategoriesRights[0]) ? {
          href: '/cost-category/add',
          icon: (<AddIcon fontSize="small" />),
          title: "add_cost_category"
        } : {}
      ]
    } : {},
    (customersRights[4]) ? {
      href: '/customers|*&*|/customer/add|*&*|/customer/[id]|*&*|/customer/[id]/edit|*&*|/store/[id]|*&*|/store/[id]/edit',
      icon: (<PeopleIcon fontSize="small"/>),
      title: "customers",
      child: [
        (customersRights[1]) ? {
          href: '/customers',
          icon: (<PeopleIcon fontSize="small" />),
          title: "show_all"
        }: {},
        (customersRights[0]) ? {
          href: '/customer/add',
          icon: (<AddIcon fontSize="small" />),
          title: "add_customer"
        }: {}
      ]
    } : {},
    (collaboratorRights[4]) ? {
      href: '/collaborators/workers|*&*|/collaborators/suppliers|*&*|/collaborators/subcontractors|*&*|/collaborator/add|*&*|/collaborator/[id]|*&*|/collaborator/[id]/edit',
      icon: (<GroupsIcon fontSize="small"/>),
      title: 'collaborators',
      child: [
        (collaboratorRights[1]) ? {
          href: '/collaborators/workers',
          icon: (<EngineeringIcon fontSize="small" />),
          title: "workers"
        }: {},
        (collaboratorRights[1]) ? {
          href: '/collaborators/suppliers',
          icon: (<StoreIcon fontSize="small" />),
          title: "suppliers"
        }: {},
        (collaboratorRights[1]) ? {
          href: '/collaborators/subcontractors',
          icon: (<GavelIcon fontSize="small" />),
          title: "subcontractors"
        }: {},
        (collaboratorRights[0]) ? {
          href: '/collaborator/add',
          icon: (<AddIcon fontSize="small" />),
          title: "add_collaborator"
        }: {}
      ]
    }: {},
    (settingsRights[4] || taxRatesRights[4]) ? {
      href: '/settings|*&*|/settings/tax-rates|*&*|/settings/manager|*&*|/settings/cities|*&*|/setting/city/add|*&*|/setting/city/[id]|*&*|/setting/city/[id]/edit|*&*|/setting/manager/[id]|*&*|/setting/manager/[id]/edit|*&*|/setting/tax-rate/add|*&*|/setting/tax-rate/[id]|*&*|/setting/tax-rate/[id]/edit',
      icon: (<SettingsIcon fontSize="small" />),
      title: "settings",
      child: [
        (settingsRights[4]) ? {
          href: '/settings/manager',
          icon: (<AttachMoneyIcon fontSize="small" />),
          title: "manager_settings"
        } : {},
        (taxRatesRights[4]) ? {
          href: '/settings/tax-rates',
          icon: (<PercentIcon fontSize="small" />),
          title: "tax_rates"
        } : {},
        (citiesRights[4]) ? {
          href: '/settings/cities',
          icon: (<LocationCityIcon fontSize="small" />),
          title: "cities"
        } : {}
      ]
    } : {}
  ];

  const content = (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: "#343A4F",
          height: "100%",
        }}
      >
        <div>
          <Box sx={{ p: 3 }}>
            <NextLink
              href="/"
              passHref
            >
              <a>
                <img src={"/assets/images/new-logo.png"}
                     width={'95%'}
                     style={{textAlign: 'center'}}
                     alt={'Logo'}/>
              </a>
            </NextLink>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: '#FFFFFF26',
            my: 3
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          <List
            sx={{ width: '100%', maxWidth: 360 }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {items.map((item) => {
                if (Object.keys(item).length !== 0) {
                  return (
                    <NavItem
                      key={item.title}
                      icon={item.icon}
                      href={item.href}
                      title={t(item.title)}
                      childItems={item.child}
                    />
                  )
                }
              }
            )}
          </List>
        </Box>
        <Divider sx={{ borderColor: '#FFFFFF' }} />
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        onClose={onClose}
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: '#343A4F',
            border: "none",
            color: '#FFFFFF',
            width: 280
          }
        }}
        variant="persistent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: '#343A4F',
          border: "none",
          color: '#FFFFFF',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="persistent"
    >
      {content}
    </Drawer>
  );
}

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
