import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import axios from 'axios';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip
} from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { useEffect, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function ProjectCostManagerSubmitCostUntilNotification({translatableMessage, projectID, projectName, untilDate, notificationID, token, handleModalClose, refreshList, fromDashboard, addedByName}){
  const router = useRouter()
  const { t } = useTranslation();
  const [untilDateFormatted, setUntilDateFormatted] = useState("")

  function generateFormattedDate(){
    const parsedDate = new Date(Date.parse(untilDate))
    let returnString = ""
    if (parsedDate.getDate() < 10){
      returnString += "0" + parsedDate.getDate().toString()
    } else {
      returnString += parsedDate.getDate().toString()
    }
    if ((parsedDate.getMonth() + 1) < 10){
      returnString += "/0" + (parsedDate.getMonth() + 1).toString()
    } else {
      returnString += "/" + (parsedDate.getMonth() + 1).toString()
    }
    returnString += "/" + parsedDate.getFullYear().toString()
    if (parsedDate.getHours() < 10){
      returnString += " 0" + parsedDate.getHours().toString()
    } else {
      returnString += " " + parsedDate.getHours().toString()
    }
    if (parsedDate.getMinutes() < 10){
      returnString += ":0" + parsedDate.getMinutes().toString()
    } else {
      returnString += ":" + parsedDate.getMinutes().toString()
    }
    setUntilDateFormatted(returnString)
  }

  useEffect(() => {
    generateFormattedDate()
  })

  const onNotificationClick = (e) => {
    if (!fromDashboard) {
      handleModalClose.call(this)
      refreshList.call(this)
    }
    router.push("/project/" + projectID + "/add-cost").then()
  }

  function removeNotificationFromNew(){
    let config = {
      method: 'get',
      url: '/api/notifications/removeFromNew/' + notificationID,
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    };

    axios(config)
      .then(function (response) {
        const allResponse = response.data
        refreshList.call(this)
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  return (
    <>
      <ListItem
        secondaryAction={
          <Tooltip title={t('read_and_close')} placement={'top'}>
            <VisibilityIcon
              onClick={removeNotificationFromNew}
            />
          </Tooltip>
        }
      >
        <ListItemButton
          onClick={onNotificationClick}
        >
          <ListItemAvatar>
            <Avatar>
              <ApartmentIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<span><b>{t('project')}: {projectName}</b> - {t(translatableMessage)} <b>{untilDateFormatted}</b></span>}
            secondary={<span>{t('added_by')}: <b>{addedByName}</b></span>}
          />
        </ListItemButton>
      </ListItem>
    </>
  )
}
