import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { Box, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import NextLink from 'next/link';
import { useCookies } from 'react-cookie';
import axios from 'axios';

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const { t } = useTranslation();
  const [cookies, setCookie, removeCookie] = useCookies(['token'])
  const router = useRouter()

  const handleSignOut = async () => {
    logoutUser()
  };

  function logoutUser() {
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: '/api/users/logout',
      headers: {
        'Authorization': 'Bearer ' + cookies.token
      }
    };

    axios(config)
      .then(function (response) {
        const allResponse = response.data
        router.push('/login').then(() => {
          removeCookie('token')
        })
      })
      .catch(function (error) {
        router.push('/login').then(() => {
          removeCookie('token')
        })
      });

  }

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: { width: '300px' }
      }}
      {...other}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2
        }}
      >
        <Typography variant="overline">
          {t('account')}
        </Typography>
        <NextLink href={'/user/' + props.userID}>
          <a>
            <Typography
              color="text.secondary"
              variant="body2"
            >
              {props.firstName + " " + props.lastName}
            </Typography>
          </a>
        </NextLink>
      </Box>
      <MenuList
        disablePadding
        sx={{
          '& > *': {
            '&:first-of-type': {
              borderTopColor: 'divider',
              borderTopStyle: 'solid',
              borderTopWidth: '1px'
            },
            padding: '12px 16px'
          }
        }}
      >
        <MenuItem onClick={handleSignOut}>
          {t('sign_out')}
        </MenuItem>
      </MenuList>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  userID: PropTypes.string.isRequired
};
