import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AuthGuard } from './auth-guard';
import { DashboardNavbar } from './dashboard-navbar';
import { DashboardSidebar } from './dashboard-sidebar';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';

export const DashboardLayout = (props) => {
  const { children } = props;
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true)
  const [renderLayout, setRenderLayout] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(['token'])
  const router = useRouter()

  const DashboardLayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    // maxWidth: '100%',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: isSidebarOpen ? 280 : 0
    }
  }));

  useEffect(() => {
    if (typeof window !== 'undefined'){
      if (firstLoad){
        if (typeof cookies.token === 'undefined' || cookies.token === null) {
          router.push("/login").then()
          return
        } else {
          setRenderLayout(true)
        }
        setFirstLoad(false)
      }
    }
  })

  if (renderLayout) {
    return (
      <AuthGuard>
        <DashboardLayoutRoot>
          <Box
            sx={{
              display: 'flex',
              flex: '1 1 auto',
              flexDirection: 'column',
              // width: '100%',
              border: "none"
            }}
          >
            {children}
          </Box>
        </DashboardLayoutRoot>
        <DashboardNavbar
          onSidebarOpen={() => setSidebarOpen(!isSidebarOpen)}
          sideBarOpen={isSidebarOpen}
        />
        <DashboardSidebar
          onClose={() => setSidebarOpen(false)}
          open={isSidebarOpen}
        />
      </AuthGuard>
    );
  }
  return (
    <>
      <div></div>
    </>
  )
};
