import NextLink from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';

export const NavItem = (props) => {
  const { href, icon, title, childItems, ...others } = props;
  const router = useRouter();
  let active = false
  const allPaths = href.split("|*&*|")
  for (let path in allPaths){
    if (allPaths[path] === router.pathname){
      active = true
      break
    }
  }

  const [open, setOpen] = useState(false)
  const [runCheck, setRunCheck] = useState(true)
  const [hoverParent, setHoverParent] = useState(false)
  const { t } = useTranslation();

  useEffect(() => {
    if (runCheck) {
      for (let i = 0; i < childItems.length; i++) {
        if (childItems[i]['href'] === router.pathname) {
          setOpen(true)
          break
        }
        setOpen(false)
      }
      setRunCheck(false)
    }

    const handleRouteChangeEnd = (url) => {
      //Ended Transitioning
      setRunCheck(true)
    }
    router.events.on("routeChangeComplete", handleRouteChangeEnd)
  })

  const handleMorePress = () => {
    setRunCheck(false)
    setOpen(!open)
  }

  return (
    <>
      <ListItemButton onClick={handleMorePress}
                      style={(active) ?
                        {backgroundColor: "#017BFE", borderRadius: "0.25rem", margin: "1rem"}
                        : {margin: "1rem"}}
                      onMouseEnter={() => setHoverParent(true)}
                      onMouseLeave={() => setHoverParent(false)}
                      sx={{
                        '&:hover': {
                          backgroundColor: "#6c757d",
                          borderRadius: "0.25rem",
                          color: "white"
                        }
                      }}
      >
        <ListItemIcon style={{
          color: (active || hoverParent) ? '#FFFFFF' : '#C2C7D0'
        }}>
          {icon}
        </ListItemIcon>
        <NextLink href={allPaths[0]}>
          <ListItemText primary={title}
          sx={{
            borderRadius: 1,
            color: (active || hoverParent) ? '#FFFFFF' : '#9CA2AA',
            fontWeight: (active || hoverParent) && 'fontWeightBold',
            '& .MuiButton-startIcon': {
              color: (active || hoverParent) ? '#FFFFFF' : '#9CA2AA'
            }
          }}
          />
        </NextLink>
        {childItems.length !== 0 &&
          <>
            { open ? <ExpandLess/> : <ExpandMore/> }
          </>
        }
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding style={{marginTop: "-10%"}}>
          {childItems.map((itemChild) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const [isHover, setIsHover] = useState(false)
            let isActive = false
            if (itemChild.href === router.pathname){
              isActive = true;
            }
            if (Object.keys(itemChild).length !== 0) {
              return (
                <>
                <NextLink href={itemChild.href}>
                  <ListItemButton style={(isActive) ?
                                    {backgroundColor: (isActive) ? "#FFFFFF" : (isHover) ? "#6c757d" : "#FFFFFF", borderRadius: "0.25rem", margin: "1rem"}
                                    : {margin: "1rem"}}
                                  onMouseEnter={() => setIsHover(true)}
                                  onMouseLeave={() => setIsHover(false)}
                                  sx={{
                                    pl: 4,
                                    '&:hover': {
                                      backgroundColor: "#6c757d",
                                      borderRadius: "0.25rem",
                                      color: "white"
                                    }
                                  }}
                  >
                    <ListItemIcon style={{
                      color: (isActive) ? '#000000' : (isHover) ? "#FFFFFF" : '#C2C7D0'
                    }}>
                      {itemChild.icon}
                    </ListItemIcon>
                      <ListItemText primary={t(itemChild.title)}
                                    sx={{
                                      borderRadius: 1,
                                      color: (isActive) ? '#6c757d' : (isHover) ? '#FFFFFF' : "#6c757d",
                                      fontWeight: isActive && 'fontWeightBold',
                                      '& .MuiButton-startIcon': {
                                        color: (isActive) ? '#6c757d' : (isHover) ? '#FFFFFF' : "#6c757d"
                                      },
                                      '&:hover': {
                                        backgroundColor: 'transparent'
                                      }
                                    }}
                      />
                  </ListItemButton>
                </NextLink>
                </>
              )
            }
            }
          )}
        </List>
      </Collapse>
    </>
  )
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
  childItems: PropTypes.array
};
