import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Toolbar,
  Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { UserCircle as UserCircleIcon } from '../icons/user-circle';
import { AccountPopover } from './account-popover';
import { useCookies } from 'react-cookie';
import jwtDecode from 'jwt-decode';
import NotificationsDropdown from './notifications-dropdown';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3]
}));

export const DashboardNavbar = (props) => {
  const { onSidebarOpen, sideBarOpen, ...other } = props;
  const settingsRef = useRef(null);
  const router = useRouter()
  const { t } = useTranslation();
  const [openAccountPopover, setOpenAccountPopover] = useState(false);
  const [renderLayout, setRenderLayout] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [cookies, setCookie, removeCookie] = useCookies(['token'])
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [userID, setUserID] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined'){
      if (firstLoad){
        if (typeof cookies.token !== 'undefined' && cookies.token !== null && cookies.token !== undefined) {
          try {
            const decodedJWT = jwtDecode(cookies.token)
            setFirstName(decodedJWT['user_details']['first_name'].toString())
            setLastName(decodedJWT['user_details']['last_name'].toString())
            setUserID(decodedJWT['user_details']['id'].toString())
            setRenderLayout(true)
          } catch (InvalidTokenError){
            router.push('/login').then
          }
        } else {
          router.push("/login").then()
          return;
        }
        setFirstLoad(false)
      }
    }
  })

  if (renderLayout) {
    return (
      <>
        <DashboardNavbarRoot
          sx={{
            left: {
              lg: sideBarOpen ? 280 : 0,
              md: sideBarOpen ? 280 : 0,
              sm: sideBarOpen ? 280 : 0,
              xs: sideBarOpen ? 280 : 0
            },
            width: sideBarOpen ? {
              lg: 'calc(100% - 280px)'
            } : {}
          }}
          {...other}>
          <Toolbar
            disableGutters
            sx={{
              minHeight: 64,
              left: 0,
              px: 2
            }}
          >
            <IconButton
              onClick={onSidebarOpen}
              sx={{
                display: {
                  xs: 'inline-flex'
                }
              }}
            >
              <MenuIcon fontSize="small"/>
            </IconButton>
            <Box sx={{ flexGrow: 1 }}/>
            <Typography
              variant={'span'}
              style={{color: "#000000"}}
            >
              {t('welcome_back')}, <i><b>{firstName} {lastName}</b></i>
            </Typography>
            <NotificationsDropdown/>
            <Avatar
              onClick={() => setOpenAccountPopover(true)}
              ref={settingsRef}
              sx={{
                cursor: 'pointer',
                height: 40,
                width: 40,
                ml: 1
              }}
              src={"/api/users/avatar/" + userID}
            >
              <UserCircleIcon fontSize="small"/>
            </Avatar>
          </Toolbar>
        </DashboardNavbarRoot>
        <AccountPopover
          anchorEl={settingsRef.current}
          open={openAccountPopover}
          onClose={() => setOpenAccountPopover(false)}
          firstName={firstName}
          lastName={lastName}
          userID={userID}
        />
      </>
    );
  }
  return (
    <>
      <div></div>
    </>
  )
};

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func
};
