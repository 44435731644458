import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import axios from 'axios';
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText, Tooltip
} from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function AdminAcceptNewCashOfferChangeNotification({
  translatableMessage,
  projectID,
  projectName,
  notificationID,
  token,
  handleModalClose,
  refreshList,
  fromDashboard,
  addedByName
}) {
  const router = useRouter();
  const { t } = useTranslation();

  const onNotificationClick = (e) => {
    if (!fromDashboard) {
      handleModalClose.call(this);
      refreshList.call(this);
    }
    router.push('/project/' + projectID + "?index=2").then();
  };

  function removeNotificationFromNew() {
    let config = {
      method: 'get',
      url: '/api/notifications/removeFromNew/' + notificationID,
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    };

    axios(config)
      .then(function (response) {
        const allResponse = response.data;
        refreshList.call(this)
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  return (
    <>
      <ListItem
        secondaryAction={
          <Tooltip title={t('read_and_close')} placement={'top'}>
            <VisibilityIcon
              onClick={removeNotificationFromNew}
            />
          </Tooltip>
        }
      >
        <ListItemButton
          onClick={onNotificationClick}
        >
          <ListItemAvatar>
            <Avatar>
              <ApartmentIcon/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<span><b>{t('project')}: {projectName}</b> - {t(translatableMessage)}</span>}
            secondary={<span>{t('added_by')}: <b>{addedByName}</b></span>}
          />
        </ListItemButton>
      </ListItem>
    </>
  );
}
